
import DeadSeaSalt from '../images/products/Benefits_DeadSeaSalt_HD_411x192.png'
import MineralChlorinatorBanner from '../images/products/Benefits_Chlorinator_HD_411x192.png'
import OzonePurificationBanner from '../images/products/Benefits_OzonePoolSystem_HD_411x192.png'
import PoolHealthBanner from '../images/products/Benefits_PoolHealth_HD_411x192.png'
// import PoolPhBanner from '../images/products/Benefits_PoolPH_HD_411x192.png'


  export const benefits = [
    {
      title:"Dead Sea Minerals",
      slug: "benefits/dead-sea-salt",      
      image:{alt:"Mineral Swim™ 100% Dead Sea Minerals",src:DeadSeaSalt},
      description:"Discover the therapeutic, health and well-being benefits of adding natural magnesium-rich Dead Sea Minerals to your swimming pool. "
    },
    {
      title:"Eco-Friendly",
      slug: "benefits/mineral-chlorinator",      
       image:{alt:"Eco-Friendly ",src:MineralChlorinatorBanner},
      description:"Maintaining a pool often involves harsh chemicals. Mineral Swim™ offers an eco-friendly alternative, reducing chemical reliance, water wastage, and costs."
    },
    {
      title:"Ozone Purification",
      slug: "benefits/ozone-pool-purification",      
       image:{alt:"Ozone Purification",src:OzonePurificationBanner},
      description:"Discover how ozone purification offers powerful, eco-friendly pool sanitation, reducing chlorine use and enhancing water quality for a healthier swim. "
    },
    {
      title:"Customer Care",
      slug: "benefits/pool-health",      
       image:{alt:"Mineral Swim™ Customer Care",src:PoolHealthBanner},
      description:"For complete peace of mind, your Mineral Swim™ system comes with dedicated lifetime support and complimentary pool system health checks after installation."
    },
    // {
    //   title:"Automatic pH Balancing",
    //   slug: "benefits/pool-ph",
    //    image:{alt:"Automatic pH Balancing",src:PoolPhBanner},
    //   description:"Automatic pH dosing makes maintaining your swimming pool's pH water balance easy for a healthy pool and comfortable bathing all year round."
    // }
  ]
